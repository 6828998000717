import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

Vue.config.productionTip = false;


import VTooltip from 'v-tooltip'
Vue.use(VTooltip)

import VueMeta from 'vue-meta'
Vue.use(VueMeta);

import customConsole from '@/mixins/console'
Vue.use(customConsole);

import Messages from './mixins/messages';
Vue.mixin(Messages);

import axios from 'axios'
Vue.prototype.$axios = axios;

let api = axios.create(store.state.auth.axiosApi);
Vue.prototype.$axiosApi = api;



var VueScrollTo = require('vue-scrollto');
Vue.use(VueScrollTo)
Vue.use(VueScrollTo, {
     container: "body",
     duration: 500,
     easing: "ease",
     offset: 200,
     force: true,
     cancelable: true,
     onStart: false,
     onDone: false,
     onCancel: false,
     x: false,
     y: true
 })



new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
