import axios from 'axios'
import Vue from 'vue'
import router from '../../router'

axios.defaults.xsrfCookieName = 'csrftoken'
axios.defaults.xsrfHeaderName = 'X-CSRFToken'




const authModule = {
    state: () => ({
        authUser: {},
        isAuthenticated: false,
        openAuth: false,
        tokenChecked: false,
        jwt: localStorage.getItem('token'),
        jwtRefresh: localStorage.getItem('rf'),
        axiosApi: {
            baseURL: process.env.VUE_APP_API,
            headers: {
                'Content-Type': 'application/json',
                'Pragma': 'no-cache',
            }
        },
        axiosAuthHeader: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,

        },
        codeRequestUserData:{},
        phoneToAuth:''

    }),
    mutations: {
        setAuthUser(state, {
            authUser,
            isAuthenticated
        }) {
            Vue.set(state, 'authUser', authUser)
            Vue.set(state, 'isAuthenticated', isAuthenticated)
        },
        setRefreshToken(state, refreshToken){
            localStorage.setItem('rf', refreshToken);
            state.jwtRefresh = refreshToken
        },
        updateToken(state, newToken) {
          // TODO: For security purposes, take localStorage out of the project.
            localStorage.setItem('token', newToken);
            state.axiosAuthHeader.Authorization = `Bearer ${newToken}`
            state.jwt = newToken;
            state.isAuthenticated = true;

            var parseJwt = function (token){
                var base64Url = token.split('.')[1];
                var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
                var jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
                    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
                }).join(''));

                return JSON.parse(jsonPayload);
            }


            state.authUser = parseJwt(newToken)
        },
        removeToken(state) {
            // TODO: For security purposes, take localStorage out of the project.
            localStorage.removeItem('token');
            state.jwt = null;
        },
        logout(state) {
            localStorage.removeItem('token');
            localStorage.removeItem('rf');
            state.authUser = {};
            state.isAuthenticated = false;
            state.tokenChecked = false;
            state.jwt = null;
            state.jwtRefresh = null;
            delete axios.defaults.headers.common['Authorization'];
        },
        setTokenChecked(state, status) {
            state.tokenChecked = status;
        },
        setOpenAuth(state, data) {
            state.openAuth = data;
        },
        setPhoneToAuth(state, data) {
            state.phoneToAuth = data;
        },
    },
    actions: {
        setCodeRequestUserData({ state, commit, rootState }, data){
            state.codeRequestUserData = data
        },
        logout({ state, commit, rootState }) {
            router.push({name: 'Home', params: {} })
            commit('logout')


        }
    }
}

export default authModule