import store from '../store'
import * as Sentry from "@sentry/browser";

export default {
    methods: {
        errorMessage(message, error, parseError){
            if(store.state.auth.isAuthenticated){
                Sentry.setUser({ id: store.state.auth.authUser.user_id });
            }

            Sentry.captureException(error);
            let strErrorCode = ''
            let strErrorMessage = ''
            if(error && parseError){
                if (error.response){
                    // strErrorMessage = JSON.stringify(error.response.data)
                    strErrorMessage = ''
                    strErrorCode = JSON.stringify(error.response.status)


                    if( typeof (error.response.data) === 'object'){
                        for (const [key, value] of Object.entries(error.response.data)) {
                            strErrorMessage += `${value}   `
                            // console.log(`${key}: ${value}`);
                        }
                    } else{
                        strErrorMessage = 'Ошибка!'
                    }

                    console.log(error.response)
                    console.log(strErrorCode)
                }
            }

            console.log(error)

            if (message === ''){
                message = 'Ошибка!'
            }

            this.$store.commit('addMessage', {
                status: 'error',
                text: message + ' '  + strErrorMessage,
            })
        },
        infoMessage(message){
            this.$store.commit('addMessage', {
                status: 'info',
                text: message
            })
        },
        successMessage(message){
            this.$store.commit('addMessage', {
                status: 'success',
                text: message
            })
        },

    },
};


