<template>
    <section class="modal-container" v-if="showModal">
        <div class="modal-container-bg"  v-on:click="onClose"></div>
        <div class="modal-window">
            <div class="modal-close-btn modal-close-btn-black" v-on:click="onClose"></div>
<!--                <component :is="modalData.component"></component>-->
                <component v-bind="modalData"></component>

        </div>
    </section>



</template>

<script>

export default {
        name: 'Modal',
        mixins: [],
        components: {},
        data(){
            return{

            }
        },
        mounted(){},
        computed: {
            showModal(){
                return this.$store.state.global.showModal
            },
            modalData(){
                return this.$store.state.global.modalData
            },
        },
        methods: {
            onClose(){
                this.$store.commit('closeModal')
            },
        },
        watch: {},
    }
</script>


<style lang="scss">
  @import "@/scss/modal.scss";
</style>