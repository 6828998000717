
const homeModule = {
    state: () => ({
        selectedFilter: 1,

    }),
    mutations:{
        setSelectedFilter(state, data) {
            state.selectedFilter = data;

            // меняем url
            let currentLocation = window.location;
            let path = currentLocation.pathname

            let pathList = path.split("/")
            pathList[pathList.length-1] = data
            let newPath = pathList.join('/')
            window.history.pushState({} , null, newPath);


        },
        setSelectedFilterWithoutUrl(state, data) {
            state.selectedFilter = data;
        },
    },
    actions: {
    }
};

export default homeModule