<template>
    <button class="btn-main"
            :disabled="disabled"
    >
        <span>{{title}}</span>
    </button>



</template>

<script>
export default {
    props: [
        'title',
        'disabled',

    ],
    mounted() {
    }
}
</script>


<style lang="scss" scoped>
    @import "@/scss/includes/_variables.scss";
    @import '@/scss/includes/bootstrap/scss/_mixins.scss';


    .btn-main{

        background-color: transparent;
        border-radius: 0px;
        border: 1px solid $red;
        font-family: 'GothamPro', 'Arial', sans-serif ;
        font-size: 14px;
        font-weight: 500;
        color:$red;
        width:100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        position: relative;
        overflow: hidden !important;
        white-space: nowrap;
        transition: all 0.25s linear;
        padding: 15px 10px;

    }


    .btn-main:focus {
        outline: none !important;
    }

    .btn-main:hover{
        color:white;
        background-color: $red;
    }






</style>