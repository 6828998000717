import Vue from 'vue'
import Vuex from 'vuex'
import messageModule from "./modules/messages";
import globalModule from "./modules/global";
import homeModule from "./modules/home";
import authModule from "./modules/auth"

Vue.use(Vuex)


export default new Vuex.Store({
    modules: {
        message: messageModule,
        global: globalModule,
        home: homeModule,
        auth: authModule,
    }
})
