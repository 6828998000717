<template>
    <nav class="menu-mobile-container" >
        <div class="menu-mobile-bg" v-on:click="close"></div>
        <div class="menu-mobile-subcontainer ">
            <div class="menu-mobile-subcontainer-content">
                <div class="container">
                    <div class="menu-mobile-header">Выбрать тенировку</div>
                    <div class="menu-mobile-content">
                        <div class="menu-mobile-content-group">
                            <router-link class="menu-item"  :to="{ name: 'CategoryMuscle' }"><div class="menu-mobile-item" :class="{current: isCurrent('CategoryMuscle')}" v-on:click="close">По группам мышц</div></router-link>
                            <router-link class="menu-item"  :to="{ name: 'CategoryDevice' }"><div class="menu-mobile-item" :class="{current: isCurrent('CategoryDevice')}" v-on:click="close">По тренажерам</div></router-link>
                            <router-link class="menu-item"  :to="{ name: 'CategoryGTO' }"><div class="menu-mobile-item" :class="{current: isCurrent('CategoryGTO')}" v-on:click="close">ГТО</div></router-link>
                            <router-link class="menu-item"  :to="{ name: 'Map' }"><div class="menu-mobile-item"  :class="{current: isCurrent('Map')}" v-on:click="close">На карте Москвы</div></router-link>
                        </div>
                        <div class="menu-mobile-content-group menu-mobile-content-group-last">
                            <router-link class="menu-item-2"  :to="{ name: 'Home' }"><div class="menu-mobile-item menu-mobile-item-item-no-margin"  :class="{current: isCurrent('Home')}" v-on:click="close">О проекте</div></router-link>

                        </div>

                    </div>
                </div>
            </div>
        </div>
        <div class="menu-mobile-footer"><v-footer></v-footer></div>

    </nav>
</template>



<script>

    import Footer from '@/components/global/Footer.vue'


export default {
    name: 'MenuMobile',
    mixins: [
    ],
    components: {
        'v-footer': Footer,

    },
    data(){
        return{
        }
    },
    mounted(){
        document.body.classList.add("modalopen");
    },
    destroyed(){
        document.body.classList.remove("modalopen");
    },
    computed:{
        showMobileMenu(){
            return  this.$store.state.global.showMobileMenu
        },
    },
    methods:{
        close(){
            this.$store.commit('setShowMobileMenu', false)
        },
        isCurrent(name){
            return this.$route.name === name
        },
    },
}
</script>

<style lang="scss" scoped>
    @import "@/scss/modal.scss";
    @import "@/scss/includes/_variables.scss";
    @import '@/scss/includes/bootstrap/scss/_mixins.scss';


.menu-mobile-container .container{
    padding-right: 0px;
}


    .#{menu-mobile} {

        &-footer{
            position: absolute;
            left: 0;
            bottom: 0;
            width: 100%;
            z-index: 50;
        }
            @include media-breakpoint-up(xs) {
                &-footer{
                    display: block;
                }
            }
            @include media-breakpoint-up(lg) {
                &-footer{
                    display: none;

                }
            }
        &-container{
            width: 100%;
            height: 100%;
            background-color: transparent;
            position: fixed;
            top:0;
            left:0;
            z-index: 399;

            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-between;
            color: white ;

        }
        @include media-breakpoint-up(xs) {
            &-container{
                padding-top: 66px;
            }
        }
        @include media-breakpoint-up(lg) {
            &-container{
                padding-top: 88px;

            }
        }


        &-subcontainer{
            width: 100%;
            z-index: 2;
            background: transparent;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: flex-start;
            position: relative;

            &-content{
                background-color: $red;
                width: 100%;

            }
                @include media-breakpoint-up(xs) {
                    &-content{
                        padding: 120px 30px;
                        height: 100%;
                    }
                }
                @include media-breakpoint-up(lg) {
                    &-content{
                        padding-top: 70px;
                        padding-bottom: 30px;
                        padding-left:90px;
                        padding-right:90px;
                        height: auto;
                    }
                }
        }
            @include media-breakpoint-up(xs) {
                &-subcontainer{
                    height: 100%;
                }
            }
            @include media-breakpoint-up(lg) {
                &-subcontainer{
                    height: auto;
                }
            }
        &-content{
            display: flex;

            &-group{
                display: flex;

                &-last{}
                    @include media-breakpoint-up(xs) {
                        &-last{
                            margin-top: 40px;
                        }
                    }
                    @include media-breakpoint-up(lg) {
                        &-last{
                            margin-top: 0;
                        }
                    }

            }
                @include media-breakpoint-up(xs) {
                    &-group{
                        flex-direction: column;
                        align-items: flex-start;
                    }
                }
                @include media-breakpoint-up(lg) {
                    &-group{
                        flex-direction: row;
                        align-items: center;
                    }
                }
        }
                @include media-breakpoint-up(xs) {
                    &-content{
                        flex-direction: column;
                        align-items: flex-start;
                    }
                }
                @include media-breakpoint-up(lg) {
                    &-content{
                        flex-direction: row;
                        align-items: center;
                        justify-content: space-between;
                    }
                }

        &-content .current{
            background-color: white !important;
            color: $red !important;
        }

        &-header{
            font-size: 14px;
            margin-bottom: 15px;
        }

        &-item {
            text-transform: uppercase;
            font-family: 'MosSport', 'Arial', sans-serif ;
            font-weight: 500;
            font-size: 42px;
            line-height: 42px;
            margin-bottom: 17px;
            cursor: pointer;
            padding: 0 5px;
            margin-left: -5px;
        }
                @include media-breakpoint-up(xs) {
                    &-item{
                        margin-right: 0px;
                    }
                }
                @include media-breakpoint-up(lg) {
                    &-item{
                        margin-right: 45px;
                    }
                }

        &-item-no-margin{margin-right: 0px;}

        &-item:hover{
            color: rgba(255,255,255,0.8);

        }

        &-item-2 {
            font-family: 'GothamPro', 'Arial', sans-serif ;
            font-weight: 500;
            font-size: 20px;
            margin-bottom: 30px;
            cursor: pointer;
        }
                @include media-breakpoint-up(xs) {
                    &-item-2{
                        margin-right: 0px;
                    }
                }
                @include media-breakpoint-up(lg) {
                    &-item-2{
                        margin-right: 30px;
                    }
                }
        &-item-2:hover{
            color: rgba(255,255,255,0.8);

        }


        &-bg{
            width: 100%;
            height: 100%;
            background-color: rgba(0,0,0,0.7);
            position: fixed;
        }


    }




</style>
