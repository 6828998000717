//- build your mixin
const mixin = {
    methods: {
        console(...message){
            if(process.env.NODE_ENV !== 'production'){
                return console.log(message)
            } else{
                return null
            }

        },

    },}

//- export it as a plugin
export default {
  install (Vue, options) {
    Vue.mixin(mixin)
  }
}




