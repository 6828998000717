<template>
	<nav class="menu-container">

		<div class="container d-flex">
			<a class="menu-item menu-logo-1" target="_blank" :href="settingsData.logo_header_1_url"
			   :style="{'background-image': 'url('+ settingsData.logo_header_1 +')'}"></a>
			<a class="menu-item menu-logo-2" target="_blank" :href="settingsData.logo_header_2_url"
			   :style="{'background-image': 'url('+ settingsData.logo_header_2 +')'}"></a>
		</div>
		<!--            <div class="container d-flex">-->
		<!--                <router-link class="menu-item menu-logo-1"  :to="{ name: 'Home' }" :style="{'background-image': 'url('+ settingsData.logo_header_1 +')'}"></router-link>-->
		<!--                <router-link class="menu-item menu-logo-2"  :to="{ name: 'Home' }" :style="{'background-image': 'url('+ settingsData.logo_header_2 +')'}"></router-link>-->
		<!--            </div>-->
		<div class="menu-burger" :class="classBurger" v-on:click="onBurger"></div>


	</nav>
</template>

<script>
    import Button from '@/components/ui/Button.vue'

    export default {
        name: 'Menu',
        props: {},
        components: {
            'v-button': Button,

        },
        mixins: [],
        mounted() {
            window.addEventListener("scroll", this.onScroll);
        },
        destroyed() {
            window.removeEventListener("scroll", this.onScroll);
        },
        data() {
            return {
                showFader: false,
            }
        },
        computed: {
            classBurger() {
                return {
                    'menu-burger-lines': !this.$store.state.global.showMobileMenu,
                    'menu-burger-close': this.$store.state.global.showMobileMenu
                }
            },
            settingsData() {
                return this.$store.state.global.settingsData
            },
        },
        methods: {
            onBurger() {
                let showMobileMenu = this.$store.state.global.showMobileMenu
                this.$store.commit('setShowMobileMenu', !showMobileMenu)
            },
            toHome() {
                this.$router.push({name: 'Home', params: {}})
            },
            onScroll() {
                let scroll = document.documentElement.scrollTop || window.scrollY
                this.showFader = scroll > 50
            },
        },
    }
</script>

<style scoped lang="scss">
	@import "@/scss/includes/_variables.scss";
	@import '@/scss/includes/bootstrap/scss/_mixins.scss';

	nav {
		display: flex;
		flex-direction: column;
		align-items: center;
		z-index: 51;
	}


	.#{menu} {

		font-size: 14px;
		text-transform: uppercase;
		position: fixed;
		width: 100%;

		&-container {
			flex-direction: row;
			align-items: center;
			justify-content: space-between;
			background-color: white;

			/*margin:  0 70px;*/
			/*height: 180px;*/
			white-space: nowrap;
			width: 100%;
			z-index: 500;
			position: fixed;
			border-bottom: 2px solid #F5F5F5;

		}

		@include media-breakpoint-up(sm) {
			&-container {
				height: 68px;

			}
		}
		@include media-breakpoint-up(lg) {
			&-container {
				height: 90px;

			}
		}

		&-burger {
			height: 100%;
			position: absolute;
			right: 0;
			top: 0;

			background-position: center;
			background-repeat: no-repeat;
			background-color: $red;

			cursor: pointer;

			&-lines {
				background-image: url('~@/assets/icons/ui/burger-icon.svg');
			}

			&-close {
				background-image: url('~@/assets/icons/ui/burger-close-icon.svg');
			}

			@include media-breakpoint-up(sm) {
				&-close {
					width: 68px;
					background-size: 20px !important;


				}
			}
			@include media-breakpoint-up(lg) {
				&-close {
					width: 90px;
					background-size: 30px !important;

				}
			}

		}

		@include media-breakpoint-up(sm) {
			&-burger {
				width: 68px;
				background-size: 25px;


			}
		}
		@include media-breakpoint-up(lg) {
			&-burger {
				width: 90px;
				background-size: 40px;

			}
		}


		&-logo-1 {
			margin: 0 !important;
			cursor: pointer;


			display: block;
			background-position: left top;
			background-size: contain;
			background-repeat: no-repeat;
			background-color: transparent;


		}

		@include media-breakpoint-up(sm) {
			&-logo-1 {
				width: 103px;
				height: 31px;
			}
		}
		@include media-breakpoint-up(lg) {
			&-logo-1 {
				width: 160px;
				height: 48px;
			}
		}

		&-logo-2 {
			cursor: pointer;
			margin-left: 30px;

			display: block;
			background-position: left top;
			background-size: contain;
			background-repeat: no-repeat;
			background-color: transparent;

		}

		@include media-breakpoint-up(sm) {
			&-logo-2 {
				width: 103px;
				height: 31px;
			}
		}
		@include media-breakpoint-up(lg) {
			&-logo-2 {
				width: 160px;
				height: 48px;
			}
		}


	}
</style>
