<template>

    <div class="container">
            <transition-group  name="messages" tag="div">
                <div
                    v-for="(message, index) in allMessages"
                    :key="index"
                >
                        <div
                            colspan="25" class="alert " role="alert"
                            v-bind:class="formatStatus(message)"
                        >
                            <button type="button" class="close  ml-3"
                                v-on:click="removeMessage(index)"
                            >
                                <span aria-hidden="true">&times;</span>
                            </button>
                            <span class="message">{{message.text}}</span>
                        </div>
                </div>
            </transition-group>
    </div>

</template>

<script>
export default {
    data(){
        return{
        }
    },
    mounted(){

    },
    computed:{
        allMessages(){
            return this.$store.state.message.messages
        }
    },
    methods:{
        formatStatus(message){
                if (message.status === "info"){
                    return 'alert-info'
                }
                if (message.status === "success"){
                    return 'alert-success'
                }
                if (message.status === "error"){
                    return 'alert-danger'
                }
        },
        removeMessage(index){
            this.$store.commit('removeMessage', index)
        },
        close(){
            this.$store.commit('deleteAllMessage')
        },
    },
    watch:{
        allMessages(newValue){
            if (Object.keys(newValue).length !==0){
                setTimeout(()=>{
                    this.close()
                }, 5000)
            }
        },
    },
}
</script>

<style lang="scss" scoped>

    @import "@/scss/includes/_variables.scss";
    @import "@/scss/includes/bootstrap/scss/bootstrap";


    .container{
        position: fixed;
        right:20px;
        z-index: 10000;
        bottom: 20px;
        /*background-color: red;*/
        display: flex;
        flex-direction: column-reverse;
    }
    @include media-breakpoint-up(xs) {
        .container{
            max-width: 300px;

        }
    }
    @include media-breakpoint-up(md) {
        .container{
            max-width: 1000px;

        }
    }


    .message{
        overflow-y: hidden;

    }

    .messages-move {
      transition: transform 0.5s;

    }
    .messages-enter-active, .messages-leave-active {
        transition: all 0.7s;

    }
    .messages-enter, .messages-leave-to {
        opacity: 0;
        transform: translateX(30px);
    }



</style>
