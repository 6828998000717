const messageModule = {
    state: () => ({
        messages: [],
    }),
    mutations:{
        addMessage(state, data) {
            let errors = state.messages.slice();
            errors.push(data)
            state.messages = errors;
        },
        removeMessage(state, index) {
            let errors = state.messages.slice();
            errors.splice (index, 1)
            state.messages = errors;
        },
        deleteAllMessage(state) {
            state.messages = [];
        },
    },

};

export default messageModule