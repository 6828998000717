<template>

        <transition name="fade">
            <div class="loader-area basic-preloader" v-if="show">

                <div class="preloader">
                    <div class="lds-grid"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
<!--                    <div class="preloader-header">Сайт находится<br>в бета-тестировании</div>-->

                </div>
            </div>
        </transition>

</template>

<script>

// import LottieAnimation from "lottie-vuejs/src/LottieAnimation.vue"

export default {
    data(){
        return{
            show:true,
            jsonUrl: '',
        }
    },
    created(){
        var url = window.location;
        this.jsonUrl = url.protocol + "//" + url.host + '/constructor/js/ama-preloader2.json/'
    },
    components: {
        // LottieAnimation
    },
    computed:{
        pageLoading(){
            return this.$store.state.global.pageLoading
        },
    },
    watch:{
        pageLoading(newValue){
            if (newValue){
                this.show = true
                document.body.classList.add('app-mounting')

            } else {
                setTimeout(()=>{
                    this.show = false
                    document.body.classList.remove('app-mounting')
                }, 1000)
            }
        },
    }
}
</script>


