import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import store from '../store'
import axios from 'axios'


Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'Home',
        component: Home
    },
    {
        path: '/muscle/:slug',
        name: 'CategoryMuscle',
        component: () => import(/* webpackChunkName: "categorie" */ '../views/CategorieMuscle.vue'),
        meta: { category: 'muscle' }
    },
    {
        path: '/device/:slug',
        name: 'CategoryDevice',
        component: () => import(/* webpackChunkName: "categorie" */ '../views/CategorieDevice.vue'),
        meta: { category: 'device' }
    },
    {
        path: '/gto/:slug',
        name: 'CategoryGTO',
        component: () => import(/* webpackChunkName: "categorie" */ '../views/CategorieGTO.vue'),
        meta: { category: 'gto' }
    },
    {
        path: '/article/:id',
        name: 'Article',
        component: () => import(/* webpackChunkName: "categorie" */ '../views/Article.vue'),
    },
    {
        path: '/map',
        name: 'Map',
        component: () => import(/* webpackChunkName: "map" */ '../views/Map.vue'),
    },

    {
        path: "*",
        redirect: { name: 'Home' }
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
    scrollBehavior (to, from, savedPosition) {
        return { x: 0, y: 0 }
    }
})







export default router
