<template>
    <section class="wrapper">
        <section class="container home-firstscreen-container top-padding" v-if="Object.keys(mainpage).lenght !==0">
<!--                    <div class="home-firstscreen-img-mob d-block d-lg-none" :style="{'background-image': 'url(' + mainpage.img + ')'}"></div>-->
                    <img class="home-firstscreen-img-mob d-block d-lg-none" :src="mainpage.img" alt="">

                    <div class="home-firstscreen-content">
                        <div class="home-firstscreen-content-header" v-html="mainpage.title"></div>
                        <div class="home-firstscreen-content-txt">{{mainpage.text}}</div>
                    </div>

                    <a href="#" v-scroll-to="'#choose-training'" class="home-firstscreen-img d-none d-lg-flex" :style="{'background-image': 'url(' + mainpage.img + ')'}">
                        <img class="home-firstscreen-img-3" src="@/assets/img/home/fs_3.svg" alt="">
                        <img src="@/assets/img/home/fs_2.svg" alt="">
                        <img src="@/assets/img/home/fs_1.svg" alt="">
                    </a>

                    <a class=" d-lg-none d-flex" href="#" v-scroll-to="'#choose-training'"><img class="home-firstscreen-icons" src="@/assets/img/home/fs_mob.svg" alt=""></a>




        </section>
        <section class="container bottom">
            <div class="home-secondscreen-container" id="choose-training">
                <div class="home-secondscreen-header">ВЫБЕРИ СВОЮ ТРЕНИРОВКУ</div>
                <div class="home-secondscreen-toggles">
                    <v-toggle :title="'По группам мышц'" :slug="1"/>
                    <v-toggle :title="'По тренажерам'"  :slug="2"/>
                    <v-toggle :title="'ГТО'"  :slug="3"/>
                </div>
            </div>
            <div class="row row-cols-1 row-cols-md-2 row-cols-lg-3">
                <div class="col"
                    v-for="item in tiles"
                >
                    <div class="home-secondscreen-item" v-on:click="onTrain(item)">

                        <div class="home-secondscreen-item-img"
                            :style="{'background-image': 'url('+ item.img +')'}"
                        ></div>
                        <div  class="home-secondscreen-item-body">
                            <div   class="home-secondscreen-item-content">
                                <div class="home-secondscreen-item-title">{{item.name}}</div>
                                <div class="home-secondscreen-item-time">{{item.duration}}</div>
                            </div>
                            <v-button
                                :title="'Смотреть упражнения'"
                                :disabled="false"
                                class="home-secondscreen-btn"
                            ></v-button>
                        </div>

                    </div>
                </div>
            </div>
        </section>
        <section class="container bottom">
            <div class="home-secondscreen-container" id="gto" >
                <div class="home-secondscreen-header" style="width: 100%;">
                    <div class="home-gto-main-header-container">
                        <div>ПОДГОТОВЬСЯ К ГТО</div>
                        <img class="home-gto-logo" src="@/assets/img/home/Logo_GTO.png" alt="gto logo">
                    </div>
                </div>
                <div class="home-secondscreen-toggles"></div>
            </div>
            <div class="home-gto-container">
                <div class="home-gto-column">
                    <div class="home-gto-header">Тренируйся правильно</div>
                    <div class="home-gto-text">Комплекс простых упражнений для подготовки к сдаче современных нормативов ГТО</div>

                    <v-button
                        :title="'Пройти'"
                        :disabled="false"
                        class="home-gto-btn"
                        @click.native="onGTO"
                    ></v-button>
                </div>
                <div class="home-gto-column home-gto-column-with-bg">
                </div>


            </div>

        </section>
    </section>



</template>

<script>



    import Toggle from '@/components/ui/Toggle.vue'
    import Button from '@/components/ui/Button.vue'

    export default {
        name: 'Home',
        metaInfo() {
            let title = this.mainpage.seo_title ? this.mainpage.seo_title : "Главная"
            let description = this.mainpage.seo_description ? this.mainpage.seo_description : ""
            return {
                title: title,
                meta: [
                    { 'name': 'description', 'content': description, },
                ]
            }
        },

        components: {
            'v-toggle': Toggle,
            'v-button':Button,
        },
        data(){
            return{
                muscleCatList:[],
                deviceCatList:[],
                gtoCatList:[],
                mainpage:{},
            }
        },
        mounted(){

            this.$store.commit('updatePageLoading', true)
            let hash = window.location.hash
            if(hash) {
                var cancelScroll = this.$scrollTo(hash)
            }

            this.getData()
            this.$store.commit('setSelectedFilterWithoutUrl', 1)
        },
        destroyed(){
        },
        computed: {
            selectedFilter(){
                return this.$store.state.home.selectedFilter
            },
            tiles(){
                if (this.selectedFilter === 1){
                    return  this.muscleCatList
                } else if (this.selectedFilter === 2){
                    return this.deviceCatList
                } else {
                    return this.gtoCatList
                }
            },
        },
        methods: {
            getData(){
                const request_1 = this.$axiosApi({
                    method: 'get',
                    url: '/content/category_muscle_list/',
                });
                const request_2 = this.$axiosApi({
                    method: 'get',
                    url: '/content/category_device_list/',
                });
                const request_3 = this.$axiosApi({
                    method: 'get',
                    url: '/content/mainpage/',
                });
                const request_4 = this.$axiosApi({
                    method: 'get',
                    url: '/content/category_gto_list/',
                });

                this.$axios.all([request_1, request_2,request_3, request_4]).then(this.$axios.spread((...responses) => {
                    this.muscleCatList = responses[0].data
                    this.deviceCatList = responses[1].data
                    this.gtoCatList = responses[3].data
                    this.mainpage = responses[2].data
                    this.$store.commit('updatePageLoading', false)
                })).catch(errors => {
                    this.errorMessage('ОШИБКА:', errors)
                })

            },
            onTrain(item){
                let category
                if(this.selectedFilter === 1){
                    category = 'CategoryMuscle'
                } else if (this.selectedFilter === 2){
                    category = 'CategoryDevice'
                } else {
                    category = 'CategoryGTO'
                }
                this.$router.push({ name: category, params: { slug: item.slug} })

            },
            onGTO(){
                this.$router.push({ name: 'CategoryGTO', params: {} })
            }
        },
        watch: {
        },


    }
</script>


<style lang="scss" >

    @import "@/scss/includes/_variables.scss";
    @import '@/scss/includes/bootstrap/scss/_mixins.scss';
    @import '@/scss/carousel.scss';

    .#{home} {

        &-firstscreen{
            &-container{
                width: 100%;
                display: flex;
                flex-direction: row;

                justify-content: space-between;


            }
                @include media-breakpoint-up(xs) {
                    &-container{
                        align-items: flex-start;
                        padding-top: 321px;
                        min-height: 920px;
                    }
                }
                @include media-breakpoint-up(md) {
                    &-container{
                        align-items: center;
                        padding-top: 0px;
                        min-height: 879px;
                    }
                }
                @include media-breakpoint-up(lg) {
                    &-container{
                        align-items: center;
                        padding-top: 90px;
                        min-height: 557px;
                    }
                }
                @include media-breakpoint-up(xl) {
                    &-container{
                        align-items: center;
                        padding-top: 90px;
                        min-height: 656px;
                    }
                }

            &-content{
                width: 100%;
                height: 100%;
                display: flex;
                flex-direction: column;
                align-items: flex-start;

                &-header{
                }
                    @include media-breakpoint-up(sm) {
                        &-header{
                            margin-bottom: 15px;
                        }
                    }
                    @include media-breakpoint-up(lg) {
                        &-header{
                            margin-bottom: 30px;
                        }
                    }

                &-header mark {
                    display: inline-block;
                    font-family: 'MosSport', 'Arial', sans-serif ;
                    color: white;
                    background-color: $red;
                    text-align: center;
                    text-transform: uppercase;


                }
                    @include media-breakpoint-up(sm) {
                        &-header mark{
                            font-size: 60px;
                            line-height: 55px;
                            height: 51px;
                            margin-bottom: 0px;
                            padding: 0 3px;

                        }
                    }
                    @include media-breakpoint-up(lg) {
                        &-header mark{
                            font-size: 80px;
                            line-height: 75px;
                            height: 70px;
                            margin-bottom: 7px;
                            padding: 0 5px;

                        }
                    }
                    @include media-breakpoint-up(xl) {
                        &-header mark{
                            font-size: 110px;
                            line-height: 100px;
                            height: 96px;
                            margin-bottom: 10px;
                            padding: 0 5px;

                        }
                    }

                &-txt{
                    font-family: 'GothamPro', 'Arial', sans-serif ;
                }
                    @include media-breakpoint-up(xs) {
                        &-txt{
                            font-size: 14px;
                            line-height: 24px;
                        }
                    }@include media-breakpoint-up(lg) {
                        &-txt{
                            font-size: 16px;
                            line-height: 28px;
                        }
                    }
                    @include media-breakpoint-up(xl) {
                        &-txt{
                            font-size: 18px;
                            line-height: 31px;
                        }
                    }


            }
                @include media-breakpoint-up(xs) {
                    &-content{
                        max-width: 100%;
                    }
                }
                @include media-breakpoint-up(lg) {
                    &-content{
                        max-width: 340px;
                    }
                }
                @include media-breakpoint-up(xl) {
                    &-content{
                        max-width: 500px;
                    }
                }


            &-img{
                position: absolute;
                right: 0;
                top:90px;
                background-position: center;
                background-size: contain;
                background-repeat: no-repeat;
                background-color: transparent;
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                justify-content: flex-end;

                &-3{
                    margin-bottom: -246px;
                    margin-left: -136px;
                }

            }
                @include media-breakpoint-up(lg) {
                    &-img{
                        min-width: 500px;
                        min-height: 467px;
                    }
                }
                @include media-breakpoint-up(xl) {
                    &-img{
                        min-width: 606px;
                        min-height: 566px;
                    }
                }

            &-img-mob{
                position: absolute;
                right: 0;
                top:68px;
                /*background-position: center center;*/
                object-fit: cover;
                /*background-size: 50%;*/
                /*-webkit-background-size: 50%;*/
                /*-moz-background-size: 50%;*/
                /*-o-background-size: 50%;*/
                /*background-repeat: no-repeat;*/
                /*background-color: transparent;*/
                width: 100%;
                min-width: 100%;
                max-width: 100%;
                height: 335px;
                z-index: -1;
            }


            &-icons{
                position: absolute;

                left: 50%;
                transform: translateX(-50%);
                max-width: 381px;
                width:100%;
            }
                @include media-breakpoint-up(xs) {
                    &-icons{
                        top: 580px;
                    }
                }
                @include media-breakpoint-up(md) {
                    &-icons{
                        top: 550px;
                    }
                }

        }

        &-secondscreen{
            &-container{
                width: 100%;
                display: flex;
                flex-direction: column;
                align-items: flex-start;
            }
                @include media-breakpoint-up(xs) {
                    &-container{
                        padding-top: 70px;
                    }
                }
                @include media-breakpoint-up(lg) {
                    &-container{
                        padding-top: 90px;
                    }
                }
                @include media-breakpoint-up(xl) {
                    &-container{
                        padding-top: 100px;
                    }
                }


            &-header{
                font-family: 'MosSport', 'Arial', sans-serif ;
                color: black;
                text-transform: uppercase;
            }
                @include media-breakpoint-up(xs) {
                    &-header{
                        font-size: 42px;
                        line-height: 42px;
                    }
                }
                @include media-breakpoint-up(lg) {
                    &-header{
                        font-size: 62px;
                        line-height: 62px;
                    }
                }
                @include media-breakpoint-up(xl) {
                    &-header{
                        font-size: 72px;
                        line-height: 72px;
                    }
                }

            &-toggles{


            }
                @include media-breakpoint-up(xs) {
                    &-toggles{
                        margin: 20px 0;
                    }
                }
                @include media-breakpoint-up(lg) {
                    &-toggles{
                        margin: 30px 0;
                    }
                }
                @include media-breakpoint-up(xl) {
                    &-toggles{
                        margin: 50px 0;
                    }
                }


            &-item{

                display: flex;
                flex-direction: column;
                align-items: flex-start;
                box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.15);
                margin-bottom: 30px;
                cursor: pointer;

                &-body{
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    justify-content: space-between;
                    min-height: 300px;
                }
                &-content{
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                }
                &-img{
                    background-position: center;
                    background-size: 80% 80%;
                    background-repeat: no-repeat;
                    background-color: $red;
                    width: 100%;
                    height: 200px;
                }

                &-title{
                    font-family: 'MosSport', 'Arial', sans-serif ;
                    color: black;
                    text-transform: uppercase;
                    font-size: 36px;
                    padding: 0 36px;
                    padding-top: 40px;
                    line-height: 40px;
                }
                &-time{
                    font-family: 'GothamPro', 'Arial', sans-serif ;
                    color: $red;
                    font-size: 14px;
                    padding: 0 36px;
                    padding-top: 24px;
                    padding-bottom: 38px;
                }
            }

            &-btn{
                max-width: 200px;
                margin-bottom: 40px;
                align-self: center;
            }
        }

        &-gto{
            &-main-header-container{
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-between;
            }
            &-logo {
                width:100px;
            }
            &-btn{
                max-width: 200px;
                margin-top: 40px;
            }
            &-container{
                display: flex;
                align-items: stretch;
                box-shadow: 0px 0px 15px rgb(0 0 0 / 15%);
                margin-bottom: 30px;
            }
                @include media-breakpoint-up(xs) {
                    &-container{
                        flex-direction: column;
                    }
                }
                @include media-breakpoint-up(lg) {
                    &-container{
                        flex-direction: row;

                    }
                }

            &-column{
                background-color: white;
                width: 100%;
                padding: 60px;
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                justify-content: center;

                &-with-bg{
                    background-image: url("~@/assets/img/home/gto_bg.png");
                    background-position: left bottom;
                    background-size: cover;
                    background-repeat: no-repeat;
                    background-color: transparent;
                }
                    @include media-breakpoint-up(xs) {
                        &-with-bg{
                            height: 200px;
                        }
                    }
                    @include media-breakpoint-up(md) {
                        &-with-bg{
                            height: 400px;
                        }
                    }
                    @include media-breakpoint-up(lg) {
                        &-with-bg{
                            height: auto;
                        }
                    }
            }

            &-header{
                display: inline-block;
                font-family: 'MosSport', 'Arial', sans-serif ;
                color: white;
                background-color: $red;
                text-align: center;
                text-transform: uppercase;
                font-size: 36px;
                line-height: 30px;
                height: 36px;
                margin-bottom: 0px;
                padding: 4px 4px;
            }
                @include media-breakpoint-up(xs) {
                    &-header{
                        font-size: 26px;
                        line-height: 24px;
                        height: 31px;
                    }
                }
                @include media-breakpoint-up(lg) {
                    &-header{
                        font-size: 36px;
                        line-height: 30px;
                        height: 36px;
                    }
                }

            &-text{
                font-size: 14px;
                line-height: 26px;
                font-weight: 400;
                margin: 20px 0;
            }
        }


    }


</style>
