<template>

	<section class="footer-container" v-if="this.$store.state.global.showFooter">
		<div class="footer-content container">
			<div class="footer-content-leftblock">
				<a :href="settingsData.logo_footer_1_url" target="_blank">
					<img
							class="footer-logo"
							:src="settingsData.logo_footer_1"
							alt="logo"

					>
				</a>
				<a :href="settingsData.logo_footer_2_url" target="_blank">
					<img
							class="footer-logo"
							:src="settingsData.logo_footer_2"
							alt="logo"

					>
				</a>
				<a :href="settingsData.logo_footer_3_url" target="_blank">
					<img
							class="footer-logo"
							:src="settingsData.logo_footer_3"
							alt="logo"

					>
				</a>
				<a :href="settingsData.logo_footer_4_url" target="_blank">
					<img
							class="footer-logo"
							:src="settingsData.logo_footer_4"
							alt="logo"

					>
				</a>
				<a :href="settingsData.logo_footer_5_url" target="_blank">
					<img
							class="footer-logo"
							:src="settingsData.logo_footer_5"
							alt="logo"

					>
				</a>
				<a :href="settingsData.logo_footer_6_url" target="_blank">
					<img
							class="footer-logo"
							:src="settingsData.logo_footer_6"
							alt="logo"

					>
				</a>
			</div>
			<div class="footer-content-rightblock">
				<span>Ⓒ Департамент спорта города Москвы {{year}}</span>
				<!--                    <span>Сделано в <a class="link-1" target="_blank" href="https://deluxe-interactive.com">Deluxe Interactive</a></span>-->
			</div>

		</div>


	</section>

</template>

<script>

    export default {
        name: 'Footer',
        props: {},
        components: {},
        mixins: [],
        mounted() {
        },
        destroyed() {
        },
        computed: {
            settingsData() {
                return this.$store.state.global.settingsData
            },
            logos() {
                return [
                    this.settingsData.logo_footer_1,
                    this.settingsData.logo_footer_2,
                    this.settingsData.logo_footer_3,
                    this.settingsData.logo_footer_4,
                    this.settingsData.logo_footer_5,
                    this.settingsData.logo_footer_6,
                ]
            },
            year() {
                return new Date().getFullYear()
            },
        },
        data() {
            return {}
        },
        methods: {
            setStyle(url) {
                return {
                    backgroundImage: 'url(' + url + ')'
                }
            },
        },
    }
</script>


<style lang="scss" scoped>
	@import "@/scss/includes/_variables.scss";
	@import "@/scss/includes/bootstrap/scss/bootstrap";

	.#{footer} {
		&-container {
			width: 100%;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			min-height: 104px;
			background-color: $light-gray;
			padding: 20px 0;
		}

		&-content {
			/*background-color: red;*/
			display: flex;


			&-rightblock {
				flex-grow: 1;
				display: flex;
				color: rgba(0, 0, 0, 0.5);
				white-space: nowrap;
			}

			@include media-breakpoint-up(xs) {
				&-rightblock {
					flex-direction: column;
					align-items: center;
					font-size: 10px;
					margin-top: 20px;
				}
			}
			@include media-breakpoint-up(lg) {
				&-rightblock {
					flex-direction: column;
					align-items: flex-end;
					justify-content: flex-end;
					font-size: 14px;
					margin-top: 0;
				}
			}
			@include media-breakpoint-up(xl) {
				&-rightblock {
					flex-direction: row;
					align-items: center;
					justify-content: flex-end;
					font-size: 14px;
					margin-top: 0;
				}
			}

			&-rightblock > span:first-child {

			}

			@include media-breakpoint-up(xs) {
				&-rightblock > span:first-child {
					margin-right: 0px;
					margin-bottom: 10px;
					margin-top: 7px;
				}
			}
			@include media-breakpoint-up(xl) {
				&-rightblock > span:first-child {
					margin-right: 20px;
					margin-bottom: 0;
					margin-top: 0px;

				}
			}


			&-leftblock {
				display: flex;
				flex-direction: row;
				align-items: center;
				flex-wrap: wrap;
			}

			@include media-breakpoint-up(xs) {
				&-leftblock {
					justify-content: center;
				}
			}
			@include media-breakpoint-up(lg) {
				&-leftblock {
					justify-content: flex-start;
				}
			}


			&-leftblock > a {
				display: flex;
				flex-direction: column;
				align-items: center;
			}

			@include media-breakpoint-up(xs) {
				&-leftblock > a {
					width: 33.33%;
				}
			}
			@include media-breakpoint-up(md) {
				&-leftblock > a {
					width: auto;
				}
			}

			&-leftblock > a:nth-child(1) {
				align-items: flex-start;
			}

			&-leftblock > a:nth-child(4) {
				align-items: flex-start;
			}

			&-leftblock > a:nth-child(3) {
				align-items: flex-end;
			}


			@include media-breakpoint-up(xs) {
				&-leftblock > a:nth-child(6) {
					align-items: flex-start;
					padding-left: 25px;

				}
			}
			@include media-breakpoint-up(md) {
				&-leftblock > a:nth-child(6) {
					align-items: flex-start;
					padding-left: 0px;

				}
			}

		}

		@include media-breakpoint-up(xs) {
			&-content {
				flex-direction: column;
				align-items: center;
				justify-content: space-between;
			}
		}
		@include media-breakpoint-up(lg) {
			&-content {
				flex-direction: row;
				align-items: center;
				justify-content: space-between;
			}
		}
		/*&-logo{*/
		/*    background-position: left;*/
		/*    background-size: contain;*/
		/*    background-repeat: no-repeat;*/
		/*    background-color: transparent;*/
		/*    width: 140px;*/
		/*    height: 40px;*/
		/*}*/


		&-logo {

		}

		@include media-breakpoint-up(xs) {
			&-logo {
				max-width: 75px;
				max-height: 24px;
				margin-right: 20px;
				margin-bottom: 20px;
			}
		}
		@include media-breakpoint-up(lg) {
			&-logo {
				max-width: 140px;
				max-height: 40px;
				margin-right: 30px;
				margin-bottom: 0px;
			}
		}


	}


</style>