<template>
    <button class="toogle"
            :id="'toggle_' + index"
            :class="toggleClass"
            v-on:click="setSelected"
    >
        {{title}}
    </button>



</template>

<script>
export default {
    props: [
        'slug',
        'title',
        'location',
        'index',



    ],
    mounted() {
        // this.checkVisibility()


    },
    computed:{
        selectedFilter(){
            return this.$store.state.home.selectedFilter
        },
        route(){
            return this.$router.currentRoute
        },
        toggleClass(){
            return {
                'toogle-categorie':this.location === 'categories',
                'toggle-mainpage':this.location !== 'categories',
                selected: this.slug === this.selectedFilter

            }
        }
    },
    methods:{
        // checkVisibility(){
        //     if(this.slug === this.selectedFilter){
        //         let element = this.$refs.toggle
        //         this.$store.commit('setToggleSelected', element)
        //     }
        //
        // },
        setSelected(){
            if (this.$router.currentRoute.name === 'Home'){
                this.$store.commit('setSelectedFilterWithoutUrl', this.slug)
            } else{
                this.$store.commit('setSelectedFilter', this.slug)
            }
        },
    },
}
</script>


<style lang="scss" scoped>
    @import "@/scss/includes/_variables.scss";
    @import '@/scss/includes/bootstrap/scss/_mixins.scss';


    .toogle{
        outline: none;
        border: none;
        font-family: 'MosSport', 'Arial', sans-serif ;

        color: rgba(0,0,0, 0.2);
        text-transform: uppercase;
        background-color: transparent;
        padding: 5px;
        margin-right: 20px;
        cursor: pointer;


    }





    .toogle:hover{
        color: rgba(0,0,0, 1);
    }

    .selected:hover {
        color: white !important;
    }

    .selected{
        background-color: $red;
        color: white;
    }

    .toogle:focus{
        outline: none;
    }



    @include media-breakpoint-up(xs) {
        .toogle-categorie{
            font-size: 24px;
            line-height: 24px;
            height: 31px;
        }
    }
    @include media-breakpoint-up(lg) {
        .toogle-categorie{
            font-size: 48px;
            line-height: 48px;
            height: 55px;
        }
    }
    @include media-breakpoint-up(xl) {
        .toogle-categorie{
            font-size: 48px;
            line-height: 48px;
            height: 59px;
        }
    }


    @include media-breakpoint-up(xs) {
        .toggle-mainpage{
            font-size: 24px;
            line-height: 24px;
            height: 31px;
        }
    }
    @include media-breakpoint-up(lg) {
        .toggle-mainpage{
            font-size: 48px;
            line-height: 48px;
            height: 55px;
        }
    }
    @include media-breakpoint-up(xl) {
        .toggle-mainpage{
            font-size: 56px;
            line-height: 53px;
            height: 59px;
        }
    }

</style>