const globalModule = {
    state: () => ({
        pageLoading:true,
        showModal:false,
        showMobileMenu:false,
        showFooter:true,
        isFullScreen:false,
        modalData:null,
        navigationTo:null,
        rules:'/rules/ПРАВИЛА_АКЦИИ_FERRERO_ROCHER.pdf',
        settingsData:{},


    }),
    mutations:{
        setIsFullScreen(state,data) {
            state.isFullScreen = data;
            let body = document.getElementsByTagName("body")[0]
            let html = document.getElementsByTagName("html")[0]
            if (data){
                body.classList.add("fullscreen");
                html.classList.add("fullscreen");
            }else{
                body.classList.remove("fullscreen");
                html.classList.remove("fullscreen");
            }
        },
        setSettingsData(state,data) {
            state.settingsData = data;
        },
        setShowFooter(state,data) {
            state.showFooter = data;
        },
        setShowMobileMenu(state,data) {
            state.showMobileMenu = data;
        },
        setNavigationTo(state,data) {
            state.navigationTo = data;
        },
        updatePageLoading(state,data) {
            state.pageLoading = data;
        },
        openModal(state,data) {
            state.showModal = true;
            state.modalData = data;
            document.body.classList.add('modalopen')
        },
        closeModal(state) {
            state.showModal = false;
            state.modalData = null;
            document.body.classList.remove('modalopen')
        },
    },

};

export default globalModule