<template>
    <div id="app" :class="{fullscreen: $store.state.global.isFullScreen}">
        <!--Menu-->
        <v-menu></v-menu>

        <!--Messages-->
        <v-messages ref="messages"></v-messages>

        <!--Content-->
        <router-view/>

        <!--Footer-->
        <v-footer></v-footer>

        <!--Mobile menu-->
        <transition name="fade">
            <v-menu-mobile v-if="this.$store.state.global.showMobileMenu"></v-menu-mobile>
        </transition>

        <!--Modal-->
        <v-modal></v-modal>


        <!--Preloader-->
        <v-preloader></v-preloader>


    </div>
</template>



<script>
    import Preloader from '@/components/global/Preloader.vue'
    import Menu from '@/components/global/Menu.vue'
    import MenuMobile from '@/components/global/MenuMobile.vue'
    import Messages from '@/components/global/Messages.vue'
    import Footer from '@/components/global/Footer.vue'
    import Modal from '@/components/global/Modal.vue'

    export default {
        name: 'App',
        components: {
            'v-menu': Menu,
            'v-messages': Messages,
            'v-footer': Footer,
            'v-preloader': Preloader,
            'v-modal': Modal,
            'v-menu-mobile': MenuMobile,
        },
        metaInfo: {
            title: 'Московский спорт',
            titleTemplate: '%s | Московский спорт'
        },
        mounted() {
            this.getData()
        },
        computed:{

        },
        methods:{
            getData(){
                this.$axiosApi({
                    method: 'get',
                    url: '/settings/',
                })
                .then(response => {
                    this.$store.commit('setSettingsData',response.data )
                })
                .catch(error => {
                    this.errorMessage('', error, false)
                });
            },
        },
        watch:{

        },

    }
</script>


<style lang="scss">
    @import "@/scss/global.scss";


</style>
